import { Observable } from 'rxjs';
import { AltaPerfilesRequestDTO, AltaPerfilesResponseDTO,
    EditarPerfilRequestDTO, EditarPerfilesResponseDTO,
    ListaUsuariosResponseDTO, DeleteRequestDTO } from 'src/app/data/dto/PerfilesDTO';

export abstract class PerfilesRepository{
    abstract crearNuevoPerfil(request: AltaPerfilesRequestDTO): Observable<AltaPerfilesResponseDTO>;
    abstract editarPerfil(request: EditarPerfilRequestDTO): Observable<EditarPerfilesResponseDTO>;
    abstract listaUsuarios(): Observable<ListaUsuariosResponseDTO>;
    abstract eliminarPerfil(request: DeleteRequestDTO): Observable<string>;
}
