import { Observable } from 'rxjs';
import {ListaMotivosRechazoResponseDTO,
        BuscarMotivoRechazoRequestDTO,
        BuscarMotivoRechazoResponseDTO,
        ActivarDesactivarMotivoRechazoRequestoDTO,
        ActivarDesactivarMotivoRechazoResponseDTO,
        AgregarMotivoRechazoRequestDTO,
        AgregarMotivoRechazoResponseDTO,
        EditarMotivoRechazoRequestDTO,
        EditarMotivoRechazoResponseDTO
        } from 'src/app/data/dto/MotivosRechazoDTO';

export abstract class MotivosRechazoRepository {
    abstract listaMotivosRechazo(): Observable<ListaMotivosRechazoResponseDTO>;
    abstract editarMotivoRechazo(request: EditarMotivoRechazoRequestDTO): Observable<EditarMotivoRechazoResponseDTO>;
    abstract agregarMotivoRechazo(request: AgregarMotivoRechazoRequestDTO): Observable<AgregarMotivoRechazoResponseDTO>;
    abstract buscarMotivoRechazo(request: BuscarMotivoRechazoRequestDTO): Observable<BuscarMotivoRechazoResponseDTO>;
    abstract activarDesactivarMotivoRechazo(
        request: ActivarDesactivarMotivoRechazoRequestoDTO): Observable<ActivarDesactivarMotivoRechazoResponseDTO>;

}
