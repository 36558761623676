import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CambioEstatusSolicitudesRepository } from 'src/app/core/repositories/CambioEstatusSolicitudes/CambioEstatusSolicitudesRepository';
import { ListadoSolicitudesEstatusResponseDTO,
         CambiarEstatusSolicitudAutorizadaRequestDTO,
         CambiarEstatusSolicitudAutorizadaResponseDTO,
         RechazoEstatusSolicitudAutorizadaRequestDTO,
         RechazoEstatusSolicitudAutorizadaResponseDTO,
         CambiarEstatusSolicitudContabilizadaRequestDTO,
         CambiarEstatusSolicitudContabilizadaResponseDTO,
         CambiarEstatusSolicitudProgramarPagoRequestDTO,
         CambiarEstatusSolicitudProgramarPagoResponseDTO,
        } from '../../dto/CambioEstatusSolicitudDTO';

@Injectable({
  providedIn: 'root',
})
export class CambioEstatusSolicitudRestRepository extends CambioEstatusSolicitudesRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listadoSolicitudesEstatusEnAutorizacion(): Observable<ListadoSolicitudesEstatusResponseDTO> {
    const endpoint = environment.listado_solicitudes_estatus_enAutorizacion_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListadoSolicitudesEstatusResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  cambiarEstatusSolicitudAutorizada(
    request: CambiarEstatusSolicitudAutorizadaRequestDTO): Observable<CambiarEstatusSolicitudAutorizadaResponseDTO> {
    const endpoint = environment.cambiar_estatus_solicitud_autorizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<CambiarEstatusSolicitudAutorizadaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  rechazoEstatusSolicitudAutorizada(
    request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO> {
    const endpoint = environment.rechazo_estatus_solicitud_autorizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RechazoEstatusSolicitudAutorizadaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listadoSolicitudesEstatusAutorizada(): Observable<ListadoSolicitudesEstatusResponseDTO> {
    const endpoint = environment.listado_solicitudes_estatus_autorizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListadoSolicitudesEstatusResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  cambiarEstatusSolicitudContabilizada(
    request: CambiarEstatusSolicitudContabilizadaRequestDTO): Observable<CambiarEstatusSolicitudContabilizadaResponseDTO> {
    const endpoint = environment.cambiar_estatus_solicitud_contabilizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<CambiarEstatusSolicitudContabilizadaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  rechazoEstatusSolicitudContabilizada(
    request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO> {
    const endpoint = environment.rechazo_estatus_solicitud_contabilizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RechazoEstatusSolicitudAutorizadaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listadoSolicitudesEstatusContabilizada(): Observable<ListadoSolicitudesEstatusResponseDTO> {
    const endpoint = environment.listado_solicitudes_estatus_contabilizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListadoSolicitudesEstatusResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  cambiarEstatusSolicitudProgramarPago(
    request: CambiarEstatusSolicitudProgramarPagoRequestDTO): Observable<CambiarEstatusSolicitudProgramarPagoResponseDTO> {
    const endpoint = environment.cambiar_estatus_solicitud_programarPago_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<CambiarEstatusSolicitudProgramarPagoResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listadoSolicitudesEstatusPagoProgramado(): Observable<ListadoSolicitudesEstatusResponseDTO> {
    const endpoint = environment.listado_solicitudes_estatus_pagoProgramado_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListadoSolicitudesEstatusResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  rechazoEstatusSolicitudProgramarPago(
    request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO> {
    const endpoint = environment.rechazo_estatus_solicitud_programarPago_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RechazoEstatusSolicitudAutorizadaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listadoSolicitudesEstatusPagada(): Observable<ListadoSolicitudesEstatusResponseDTO> {
    const endpoint = environment.listado_solicitudes_estatus_pagada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListadoSolicitudesEstatusResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
