import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserRepository } from '../../../core/repositories/auth/UserRepository';
import { Injectable } from '@angular/core';
import { LoginDTO, LoginResponseDTO } from '../../dto/LoginDTO';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserRestRepository extends UserRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  login(auth: LoginDTO): Observable<LoginResponseDTO> {
    const endpoint = environment.login_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<LoginDTO>(`${environment.url_base}/${endpoint}`, auth, httpOptions)
      .pipe(
        map((user) => {
          return this.auth.login(user);
        })
      );
  }

  logout(): Observable<string> {
    this.auth.logout();
    const subjet = new BehaviorSubject<string>('login');
    return subjet.asObservable();
  }
}
