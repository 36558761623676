<nav
  class="navbar navbar-expand navbar-light bg-white topbar static-top shadow"
>
  <div
    class="
      d-sm-inline-block
      form-inline
      mr-auto
      ml-md-3
      my-2 my-md-0
      mw-100
      navbar-search
    "
  >
    <img
      src="/assets/img/logo-comercial.png"
      class="d-inline-block align-top img-logo"
      alt="Logo"
    />
  </div>

  <!-- Topbar Navbar -->
  <ul class="navbar-nav ml-auto">
    <!-- Nav Item - User Information -->
    <li class="nav-item dropdown no-arrow">
      <a
        class="nav-link dropdown-toggle"
        [routerLink]=""
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Admin</span>
        <img
          class="img-profile rounded-circle"
          src="/assets/img/svg/undraw_profile.svg"
        />
      </a>
      <!-- Dropdown - User Information -->
      <div
        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <a class="dropdown-item" [routerLink]="">
          <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          Perfil
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item"
          [routerLink]=""
          data-toggle="modal"
          (click)="closeLogout()"
        >
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Cerrar sesión
        </a>
      </div>
    </li>
  </ul>
</nav>
