import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ListaUsuariosResponseDTO,
        } from '../../dto/UsuariosDTO';
import { UsuariosRepository
        } from 'src/app/core/repositories/Usuarios/UsuariosRepository';

@Injectable({
    providedIn: 'root',
})

export class UsuariosRestRepository extends UsuariosRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listaUsuarios(): Observable<ListaUsuariosResponseDTO> {
        const endpoint = environment.lista_usuarios_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ListaUsuariosResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }
}
