export abstract class Localization {
  appTitle: string;
  appCredentialError: string;
  page404Title: string;
  page404Text: string;
  errorService: string;
  userName: string;
  userPassword: string;
  userRequired: string;
  passwordRequired: string;
  titleHome: string;
  welcomeHome: string;
  headerLogOut: string;
  buttonModalClose: string;
  buttonDeleteModal: string;
  buttonReturn: string;
  buttonSend: string;
  buttonSave: string;
  buttonContinue: string;
  goodMorning: string;
  goodAfternoon: string;
  goodEvening: string;
  menuTitle: string;
  inputRequired: string;
  comboRequired: string;
  inputpattern: string;
  inputRequiredArchivo: string;
  inputRequiredArchivoExcel: string;
}

export class SpanishLocalization implements Localization {
  appTitle = 'Control Traslado';
  appCredentialError = 'El usuario y/o contraseña son incorrectos.';
  page404Title = 'Página no encontrada';
  page404Text = 'Lo sentimos, esta página no esta disponible o no existe';
  errorService = 'Lo sentimos, ocurrio un error';
  userName = 'Usuario:';
  userPassword = 'Contraseña:';
  userRequired = 'Debes ingresar un usuario válido';
  passwordRequired = 'Debes ingresar una contraseña valida';
  titleHome = 'Bienvenido';
  welcomeHome = 'Para comenzar, debes seleccionar una opción en la parte superior.';
  headerLogOut = 'Cerrar sesión';
  buttonModalClose = 'Cerrar';
  buttonDeleteModal = 'Si, eliminar';
  buttonReturn = 'Regresar';
  buttonSend = 'Envíar';
  buttonSave = 'Guardar';
  buttonContinue = 'Continuar';
  goodMorning = 'Buenos días';
  goodAfternoon = 'Buenas tardes';
  goodEvening = 'Buenas noches';
  menuTitle = 'Menú';
  inputRequired = 'Este campo es requerido';
  comboRequired = 'Debes seleccionar una opción';
  inputpattern = 'Ingresar solo números';
  inputPatternText = 'Ingresar solo letras';
  inputMaxLength = 'Ingresar solo una letra';
  inputMinLength = 'Ingresar solo una letra';
  inputRequiredArchivo = 'Cargue un archivo con extendión .pdf ó .jpg';
  inputRequiredArchivoExcel = 'Cargue un archivo Excel (extendión .xls)';
  inputMaxLength25 = 'Máximo 25 carácteres';
}
