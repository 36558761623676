import { Observable } from 'rxjs';
import {
    ListImpuestosResponseDTO,
    BuscarImpuestoRequestDTO,
    EditarImpuestoRequestDTO,
    EditarImpuestoResponseDTO,
    ActivarDesactivarImpuestoRequestDTO,
    ActivarDesactivarImpuestoResponseDTO,
    AgregarImpuestoRequestDTO,
    AgregarImpuestoResponseDTO,
} from 'src/app/data/dto/ImpuestosDTO';

export abstract class ImpuestosRepository {
    abstract listaImpuestos(): Observable<ListImpuestosResponseDTO>;
    abstract agregarImpuesto(request: AgregarImpuestoRequestDTO): Observable<AgregarImpuestoResponseDTO>;
    abstract editarImpuesto(request: EditarImpuestoRequestDTO): Observable<EditarImpuestoResponseDTO>;
    abstract buscarImpuesto(request: BuscarImpuestoRequestDTO): Observable<ListImpuestosResponseDTO>;
    abstract activarDesactivarImpuesto(request: ActivarDesactivarImpuestoRequestDTO): Observable<ActivarDesactivarImpuestoResponseDTO>;
}

