import { Observable } from 'rxjs';
import {
    ListaSociedadResponseDTO,
    NuevaSociedadRequestDTO,
    NuevaSociedadResponseDTO,
    EditarSociedadRequestDTO,
    EditarSociedadResponseDTO,
    BuscarSociedadRequestDTO,
    BuscarSociedadResponseDTO,
    ActivarDesactivarSociedadRequestDTO,
    ActivarDesactivarSociedadResponseDTO,
} from 'src/app/data/dto/SociedadDTO';

export abstract class SociedadRepository {
    abstract listaSociedades(): Observable<ListaSociedadResponseDTO>;
    abstract nuevaSociedad(request: NuevaSociedadRequestDTO): Observable<NuevaSociedadResponseDTO>;
    abstract editarSociedad(request: EditarSociedadRequestDTO): Observable<EditarSociedadResponseDTO>;
    abstract buscarSociedad(request: BuscarSociedadRequestDTO): Observable<BuscarSociedadResponseDTO>;
    abstract activarDesactivarSociedad(request: ActivarDesactivarSociedadRequestDTO): Observable<ActivarDesactivarSociedadResponseDTO>;
}

