import { Observable } from 'rxjs';
import {
    ListaAreasDepartamentosResponseDTO,
    AgregarAreaDepartamentoRequestDTO,
    AgregarAreaDepartamentoResponseDTO,
    EditarAreaDepartamentoRequestDTO,
    EditarAreaDepartamentoResponseDTO,
    ActivarDesactivarAreaDepartamentoRequestDTO,
    ActivarDesactivarAreaDepartamentoResponseDTO,
    BuscarAreaDepartamentoRequestDTO,
    BuscarAreaDepartamentoResponseDTO,
} from 'src/app/data/dto/AreaDepartamentoDTO';

export abstract class AreaDepartamentoRepository {
    abstract listaAreasDepartamentos(): Observable<ListaAreasDepartamentosResponseDTO>;
    abstract agregarAreaDepartamento(request: AgregarAreaDepartamentoRequestDTO): Observable<AgregarAreaDepartamentoResponseDTO>;
    abstract editarAreaDepartamento(request: EditarAreaDepartamentoRequestDTO): Observable<EditarAreaDepartamentoResponseDTO>;
    abstract buscarAreaDepartamento(request: BuscarAreaDepartamentoRequestDTO): Observable<BuscarAreaDepartamentoResponseDTO>;
    abstract activarDesactivarAreaDepartamento(
        request: ActivarDesactivarAreaDepartamentoRequestDTO): Observable<ActivarDesactivarAreaDepartamentoResponseDTO>;
}
