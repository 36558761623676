import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import {ListaAreasDepartamentosResponseDTO,
        AgregarAreaDepartamentoRequestDTO,
        AgregarAreaDepartamentoResponseDTO,
        EditarAreaDepartamentoRequestDTO,
        EditarAreaDepartamentoResponseDTO,
        ActivarDesactivarAreaDepartamentoRequestDTO,
        ActivarDesactivarAreaDepartamentoResponseDTO,
        BuscarAreaDepartamentoRequestDTO,
        BuscarAreaDepartamentoResponseDTO,
        } from '../../dto/AreaDepartamentoDTO';
import { AreaDepartamentoRepository } from 'src/app/core/repositories/AreaDepartamento/AreaDepartamentoRepository';
@Injectable({
    providedIn: 'root',
})
export class AreaDepartamentoRestRepository extends AreaDepartamentoRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listaAreasDepartamentos(): Observable<ListaAreasDepartamentosResponseDTO> {
        const endpoint = environment.lista_area_departamento_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ListaAreasDepartamentosResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    agregarAreaDepartamento(request: AgregarAreaDepartamentoRequestDTO): Observable<AgregarAreaDepartamentoResponseDTO> {
        const endpoint = environment.agregar_area_departamento_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
          .post<AgregarAreaDepartamentoResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

    editarAreaDepartamento(request: EditarAreaDepartamentoRequestDTO): Observable<EditarAreaDepartamentoResponseDTO> {
        const endpoint = environment.editar_area_departamento_endpoint;
        const {intIDArea , ...area} = request;
        const httpOptions = { headers: this.headers };
        return this.http
          .put<EditarAreaDepartamentoResponseDTO>(
            `${environment.url_base}/${endpoint}/${intIDArea}`,
            JSON.stringify(area),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      buscarAreaDepartamento(request: BuscarAreaDepartamentoRequestDTO): Observable<BuscarAreaDepartamentoResponseDTO> {
        const endpoint = environment.buscar_area_departamento_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
          .post<BuscarAreaDepartamentoResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      activarDesactivarAreaDepartamento(
        request: ActivarDesactivarAreaDepartamentoRequestDTO): Observable<ActivarDesactivarAreaDepartamentoResponseDTO> {
        const endpoint = environment.activar_desactivar_area_departamento_endpoint;
        const {intIDArea, ...area} = request;
        const httpOptions = { headers: this.headers };
        return this.http
          .put<ActivarDesactivarAreaDepartamentoResponseDTO>(
            `${environment.url_base}/${endpoint}/${intIDArea}`,
            JSON.stringify(area),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }
}
