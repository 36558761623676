import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CuentasContablesRepository } from 'src/app/core/repositories/CuentasContables/CuentasContablesRepository';
import { ListadoCuentasContablesResponseDTO } from '../../dto/CuentasContablesDTO';

@Injectable({
  providedIn: 'root',
})
export class CuentasContablesRestRepository extends CuentasContablesRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listaCuentasContables(): Observable<ListadoCuentasContablesResponseDTO> {
    const endpoint = environment.lista_cuentas_contables_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
    .get<ListadoCuentasContablesResponseDTO>(
      `${environment.url_base}/${endpoint}`,
      httpOptions
    )
    .pipe(
      map((result) => {
        return result;
      })
    );
  }
}
