import { Observable } from 'rxjs';
import {ListaEstatusSolicitudesResponseDTO,
        EditarEstatusSolicitudRequestDTO,
        EditarEstatusSolicitudResponseDTO,
        ActivarDesactivarEstutusSolicitudRequestDTO,
        ActivarDesactivarEstutusSolicitudResponseDTO,
        BuscarEstatusSolicitudRequestDTO,
        BuscarEstatusSolicitudResponseDTO,
        AgregarEstatusSolicitudRequestDTO,
        AgregarEstatusSolicitudResponseDTO
        } from 'src/app/data/dto/EstatusSolicitudesDTO';

export abstract class EstatusSolicitudesRepository {
    abstract listaEstatusSolicitudes(): Observable<ListaEstatusSolicitudesResponseDTO>;
    abstract editarEstatusSolicitud(request: EditarEstatusSolicitudRequestDTO): Observable<EditarEstatusSolicitudResponseDTO>;
    abstract agregarEstatusSolicitud(request: AgregarEstatusSolicitudRequestDTO): Observable<AgregarEstatusSolicitudResponseDTO>;
    abstract buscarEstatusSolicitud(request: BuscarEstatusSolicitudRequestDTO): Observable<BuscarEstatusSolicitudResponseDTO>;
    abstract activarDesactivarEstatusSolicitud(
        request: ActivarDesactivarEstutusSolicitudRequestDTO): Observable<ActivarDesactivarEstutusSolicitudResponseDTO>;

}
