import { Observable } from 'rxjs';
import { ListadoSolicitudesEstatusResponseDTO,
         CambiarEstatusSolicitudAutorizadaRequestDTO,
         CambiarEstatusSolicitudAutorizadaResponseDTO,
         RechazoEstatusSolicitudAutorizadaRequestDTO,
         RechazoEstatusSolicitudAutorizadaResponseDTO,
         CambiarEstatusSolicitudContabilizadaRequestDTO,
         CambiarEstatusSolicitudContabilizadaResponseDTO,
         CambiarEstatusSolicitudProgramarPagoRequestDTO,
         CambiarEstatusSolicitudProgramarPagoResponseDTO,
        } from 'src/app/data/dto/CambioEstatusSolicitudDTO';

export abstract class CambioEstatusSolicitudesRepository {
    abstract listadoSolicitudesEstatusEnAutorizacion(): Observable<ListadoSolicitudesEstatusResponseDTO>;
    abstract cambiarEstatusSolicitudAutorizada(
        request: CambiarEstatusSolicitudAutorizadaRequestDTO): Observable<CambiarEstatusSolicitudAutorizadaResponseDTO>;
    abstract rechazoEstatusSolicitudAutorizada(
        request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO>;
    abstract listadoSolicitudesEstatusAutorizada(): Observable<ListadoSolicitudesEstatusResponseDTO>;
    abstract cambiarEstatusSolicitudContabilizada(
        request: CambiarEstatusSolicitudContabilizadaRequestDTO): Observable<CambiarEstatusSolicitudContabilizadaResponseDTO>;
    abstract rechazoEstatusSolicitudContabilizada(
                request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO>;
    abstract listadoSolicitudesEstatusContabilizada(): Observable<ListadoSolicitudesEstatusResponseDTO>;
    abstract cambiarEstatusSolicitudProgramarPago(
        request: CambiarEstatusSolicitudProgramarPagoRequestDTO): Observable<CambiarEstatusSolicitudProgramarPagoResponseDTO>;
    abstract rechazoEstatusSolicitudProgramarPago(
        request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO>;
    abstract listadoSolicitudesEstatusPagoProgramado(): Observable<ListadoSolicitudesEstatusResponseDTO>;
    abstract listadoSolicitudesEstatusPagada(): Observable<ListadoSolicitudesEstatusResponseDTO>;
}

