import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ImpuestosRepository } from 'src/app/core/repositories/Impuestos/ImpuestosRepository';
import { ActivarDesactivarImpuestoRequestDTO,
         ActivarDesactivarImpuestoResponseDTO,
         AgregarImpuestoRequestDTO,
         AgregarImpuestoResponseDTO,
         BuscarImpuestoRequestDTO,
         EditarImpuestoRequestDTO,
         EditarImpuestoResponseDTO,
         ListImpuestosResponseDTO,
        } from '../../dto/ImpuestosDTO';

@Injectable({
  providedIn: 'root',
})
export class ImpuestosRestRepository extends ImpuestosRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }



  listaImpuestos(): Observable<ListImpuestosResponseDTO> {
    const endpoint = environment.lista_impuestos_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListImpuestosResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarImpuesto(request: AgregarImpuestoRequestDTO): Observable<AgregarImpuestoResponseDTO> {
    const endpoint = environment.agregar_impuesto_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarImpuestoResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editarImpuesto(request: EditarImpuestoRequestDTO): Observable<EditarImpuestoResponseDTO> {
    const endpoint = environment.editar_impuesto_endpoint;
    const {chrIDImpuesto, ...impuesto} = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<EditarImpuestoResponseDTO>(
        `${environment.url_base}/${endpoint}/${chrIDImpuesto}`,
        JSON.stringify(impuesto),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarImpuesto(request: BuscarImpuestoRequestDTO): Observable<ListImpuestosResponseDTO> {
    const endpoint = environment.buscar_impuesto_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<ListImpuestosResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  activarDesactivarImpuesto(request: ActivarDesactivarImpuestoRequestDTO): Observable<ActivarDesactivarImpuestoResponseDTO> {
    const endpoint = environment.activar_desactivar_impuesto_endpoint;
    const {chrIDImpuesto, ...impuesto} = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ActivarDesactivarImpuestoResponseDTO>(
        `${environment.url_base}/${endpoint}/${chrIDImpuesto}`,
        JSON.stringify(impuesto),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
