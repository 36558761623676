import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Localization } from 'src/app/shared/Localization';
import { AuthService } from './../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    localization: Localization;

    constructor(
        private authenticationService: AuthService,
        localization: Localization
    ) {
        this.localization = localization;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
        let error = err.error.message || err.statusText;
        if (err.status === 401) {
            // auto logout if 401 response returned from api
            this.authenticationService.logout();
            error = this.localization.appCredentialError;
        }
        return throwError(error);
    }));
    }
}
