const url = 'https://formatounicoqabackend.gservicio.com';

export const environment = {
  production: true,
  url_base: `${url}/api/v1`,
  url: `${url}`,
  login_endpoint: 'auth',
  lista_sociedades_endpoint: 'catalogoSociedad/list',
  nueva_sociedad_endpoint: 'catalogoSociedad/create',
  editar_sociedad_endpoint: 'catalogoSociedad/update',
  buscar_sociedad_endpoint: 'catalogoSociedad',
  activar_desactivar_sociedad_endpoint: 'catalogoSociedad/estatus',
  lista_monedas_endpoint: 'catalogoMoneda/list',
  insertar_moneda_endpoint: 'catalogoMoneda/create',
  editar_moneda_endpoint: 'catalogoMoneda/update',
  buscar_moneda_endpoint: 'catalogoMoneda',
  activar_desactivar_moneda_endpoint: 'catalogoMoneda/estatus',
  lista_autorizadores_area_endpoint: 'catalogoAutorizadoresArea',
  agregar_autorizador_area_endpoint: 'catalogoAutorizadoresArea/create',
  buscar_autorizador_area_endpoint: 'catalogoAutorizadoresArea/autorizador',
  activar_desactivar_autorizador_area_endpoint: 'catalogoAutorizadoresArea/estatus',
  editar_autorizador_area_endpoint: 'catalogoAutorizadoresArea/update',
  lista_area_departamento_endpoint: 'catalogoAreasDepartamentos/list',
  agregar_area_departamento_endpoint: 'catalogoAreasDepartamentos/create',
  editar_area_departamento_endpoint: 'catalogoAreasDepartamentos/update',
  activar_desactivar_area_departamento_endpoint: 'catalogoAreasDepartamentos/estatus',
  buscar_area_departamento_endpoint: 'catalogoAreasDepartamentos',
  lista_impuestos_endpoint: 'catalogoImpuestos/list',
  buscar_impuesto_endpoint: 'catalogoImpuestos',
  editar_impuesto_endpoint: 'catalogoImpuestos/update',
  activar_desactivar_impuesto_endpoint: 'catalogoImpuestos/estatus',
  agregar_impuesto_endpoint: 'catalogoImpuestos/create',
  lista_solicitud_endpoint: 'listEstatus/capturada',
  agregar_solicitud_endpoint: 'solicitudPago/create',
  aplicar_detalle_solicitud_endpoint: 'solicitudPago/detalles/create',
  buscar_detalle_solicitud_endpoint: 'solicitudPago/detalles/list',
  buscar_solicitud_pago_sin_pedido: 'solicitudPago/busqueda',
  aplicar_monto_total_solicitud_endpoint: 'solicitudPago/total',
  agregar_tipo_pago_cheque_endpoint: 'tipoPago/cheque',
  agregar_tipo_pago_transferencia_endpoint: 'tipoPago/transferencia',
  agregar_recibo_tipo_pago_cheque_endpoint: 'tipoPago/carga/recibo',
  agregar_pago_extranjero_endpoint: 'tipoPago/extranjero',
  lista_solicitantes_area_endpoint: 'catalogoSolicitantesArea/list',
  buscar_solicitante_area_endpoint: 'catalogoSolicitantesArea/find',
  editar_solicitante_area_endpoint: 'catalogoSolicitantesArea/update',
  activar_desactivar_solicitante_area_endpoint: 'catalogoSolicitantesArea/estatus',
  agregar_solicitante_endpoint: 'catalogoSolicitantesArea/create',
  lista_usuarios_endpoint: 'user/list',
  agregar_archivo_carga_prorrateo_endpoint: 'archivosCargaProrrateo',
  lista_motivos_rechazo_endpoint: 'catalogoMotivoRechazo/list',
  buscar_motivos_rechazo_endpoint: 'catalogoMotivoRechazo',
  activar_desactivar_motivo_rechazo_endpoint: 'catalogoMotivoRechazo/estatus',
  agregar_motivo_rechazo_endpoint: 'catalogoMotivoRechazo/create',
  editar_motivo_rechazo_endpoint: 'catalogoMotivoRechazo/update',
  lista_estatus_solicitudes_endpoint: 'catalogoEstatus/list',
  editar_estatus_solicitud_endpoint: 'catalogoEstatus/update',
  activar_desactivar_estatus_solicitud_endpoint: 'catalogoEstatus/estatus',
  buscar_estatus_solicitud_endpoint: 'catalogoEstatus',
  agregar_estatus_solicitud_endpoint: 'catalogoEstatus/create',
  cambiar_estatus_solicitud_enAutorizacion_endpoint: 'autorizaciones/validacion/factura',
  cambiar_estatus_solicitud_autorizada_endpoint: 'autorizaciones/autorizar',
  rechazo_estatus_solicitud_autorizada_endpoint: 'autorizaciones/rechazar',
  cambiar_estatus_solicitud_contabilizada_endpoint: 'autorizaciones/contabilizar/aceptada',
  rechazo_estatus_solicitud_contabilizada_endpoint: 'autorizaciones/contabilizar/rechazada',
  cambiar_estatus_solicitud_programarPago_endpoint: 'autorizaciones/programar/pago',
  rechazo_estatus_solicitud_programarPago_endpoint: 'autorizaciones/programar/pago/rechazado',
  visor_factura_XML_endpoint: 'consultarFacturas',
  listado_solicitudes_estatus_enAutorizacion_endpoint: 'listEstatus/enAutorizacion',
  listado_solicitudes_estatus_autorizada_endpoint: 'listEstatus/autorizada',
  listado_solicitudes_estatus_contabilizada_endpoint: 'listEstatus/contabilizada',
  listado_solicitudes_estatus_pagoProgramado_endpoint: 'listEstatus/pago/programado',
  listado_solicitudes_estatus_pagada_endpoint: 'listEstatus/pagada',
  buscar_Acreedor_Por_Sociedad_endpoint: 'solicitudPago/consulta/acreedor',
  lista_centro_costos_endpoint: 'centroCostos/list',
  lista_cuentas_contables_endpoint: 'cuentasContables/list',
  editar_detalle_endpoint: 'solicitudPago/detalles/update',
  delete_detalle_endpoint: 'solicitudPago/detalles/estatus',
  agregar_entrega_cheque_endpoint: 'entregaCheque',
  agregar_comprobante_endpoint: 'entregaCheque/comprobante',
  agregar_perfil_endpoint: 'user/create',
  editar_perfil_endpoint: 'user/update',
  pagar_solicitud_endpoint: 'pagarSolicitud',
  lista_perfiles_endpoint: 'user/list',
  elimina_perfil_endpoint: 'user/delete',
};
