import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { MotivosRechazoRepository } from 'src/app/core/repositories/MotivosRechazo/MotivosRechazoRepository';
import {
  ActivarDesactivarMotivoRechazoRequestoDTO,
  ActivarDesactivarMotivoRechazoResponseDTO,
  AgregarMotivoRechazoRequestDTO,
  AgregarMotivoRechazoResponseDTO,
  BuscarMotivoRechazoRequestDTO,
  BuscarMotivoRechazoResponseDTO,
  EditarMotivoRechazoRequestDTO,
  EditarMotivoRechazoResponseDTO,
  ListaMotivosRechazoResponseDTO,
} from '../../dto/MotivosRechazoDTO';

@Injectable({
  providedIn: 'root',
})
export class MotivosRechazoRestRepository extends MotivosRechazoRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }



  listaMotivosRechazo(): Observable<ListaMotivosRechazoResponseDTO> {
    const endpoint = environment.lista_motivos_rechazo_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListaMotivosRechazoResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarMotivoRechazo(request: AgregarMotivoRechazoRequestDTO): Observable<AgregarMotivoRechazoResponseDTO> {
    const endpoint = environment.agregar_motivo_rechazo_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarMotivoRechazoResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarMotivoRechazo(request: BuscarMotivoRechazoRequestDTO): Observable<BuscarMotivoRechazoResponseDTO> {
    const endpoint = environment.buscar_motivos_rechazo_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<BuscarMotivoRechazoResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  activarDesactivarMotivoRechazo(
    request: ActivarDesactivarMotivoRechazoRequestoDTO): Observable<ActivarDesactivarMotivoRechazoResponseDTO> {
    const endpoint = environment.activar_desactivar_motivo_rechazo_endpoint;
    const { intIDMotivo, ...motivo } = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ActivarDesactivarMotivoRechazoResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDMotivo}`,
        JSON.stringify(motivo),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editarMotivoRechazo(request: EditarMotivoRechazoRequestDTO): Observable<EditarMotivoRechazoResponseDTO> {
    const endpoint = environment.editar_motivo_rechazo_endpoint;
    const { intIDMotivo, ...motivo } = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<EditarMotivoRechazoResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDMotivo}`,
        JSON.stringify(motivo),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
