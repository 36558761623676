import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AltaPerfilesRequestDTO, AltaPerfilesResponseDTO,
  EditarPerfilRequestDTO, EditarPerfilesResponseDTO,
  ListaUsuariosResponseDTO, DeleteRequestDTO } from 'src/app/data/dto/PerfilesDTO';
import { PerfilesRepository } from 'src/app/core/repositories/Perfiles/PerfilesRepository';

@Injectable({
    providedIn: 'root',
  })
  export class PerfilesRestRepository extends PerfilesRepository{
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private auth: AuthService) {
      super();
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }

    crearNuevoPerfil(request: AltaPerfilesRequestDTO): Observable<AltaPerfilesResponseDTO> {
      const endpoint = environment.agregar_perfil_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .post<AltaPerfilesResponseDTO>(
        `${environment.url_base}/${endpoint}`,
          JSON.stringify(request),
          httpOptions
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    editarPerfil(request: EditarPerfilRequestDTO): Observable<EditarPerfilesResponseDTO>{
      const endpoint = environment.editar_perfil_endpoint;
      const { id, ...motivo } = request;
      const httpOptions = { headers: this.headers };
      return this.http
      .put<EditarPerfilesResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(motivo),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
    }

    listaUsuarios(): Observable<ListaUsuariosResponseDTO> {
      const endpoint = environment.lista_perfiles_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .get<ListaUsuariosResponseDTO>(
          `${environment.url_base}/${endpoint}`,
          httpOptions
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    eliminarPerfil(request: DeleteRequestDTO): Observable<string>{
      const endpoint = environment.elimina_perfil_endpoint;
      const { id, ...motivo } = request;
      const httpOptions = { headers: this.headers };
      return this.http
      .put<string>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(motivo),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
    }
  }
