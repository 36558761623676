import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { EntregaChequesRepository } from 'src/app/core/repositories/EntregaCheques/EntregaChequesRpository';
import { AgregarEntregaChequeRequiestDTO,
    AgregarEntregaChequeResponseDTO,
    ComprobanteRequiestDTO,
    ComprobanteResponseDTO } from 'src/app/data/dto/EntregaChequesDTO';

@Injectable({
    providedIn: 'root',
})
export class EntregarChequeRestRepository extends EntregaChequesRepository{
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        });
    }

    agregarEntregaCheque(request: AgregarEntregaChequeRequiestDTO): Observable<AgregarEntregaChequeResponseDTO> {
        const endpoint = environment.agregar_entrega_cheque_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
        .post<AgregarEntregaChequeResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
        )
        .pipe(
            map((result) => {
            return result;
            })
        );
    }

    agregarComprobante(request: ComprobanteRequiestDTO): Observable<ComprobanteResponseDTO> {
        const endpoint = environment.agregar_comprobante_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.post<ComprobanteResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
        );
    }
}
