import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { SolicitudRepository } from 'src/app/core/repositories/Solicitud/SolicitudRepository';
import { AgregarArchivoCargaProrrateoRequestDTO, AgregarArchivoCargaProrrateoResponseDTO, AgregarPagoExtranjeroRequestDTO,
         AgregarPagoExtranjeroResponseDTO,
         AgregarReciboTipoPagoChequeResponseDTO,
         AgregarSolicitudRequestDTO,
         AgregarSolicitudResponseDTO,
         AgregarTipoPagoChequeRequestDTO,
         AgregarTipoPagoChequeResponseDTO,
         AgregarTipoPagoTransferenciaRequestDTO,
         AgregarTipoPagoTransferenciaResponseDTO,
         AplicarDetalleSolicitudRequestDTO,
         AplicarDetalleSolicitudResponseDTO,
         AplicarMontoTotalSolicitudRequestDTO,
         AplicarMontoTotalSolicitudResponseDTO,
         BuscarAcreedorPorSociedadRequestDTO,
         BuscarAcreedorPorSociedadResponseDTO,
         BuscarDetalleDeSolicitudRequestDTO,
         BuscarDetalleDeSolicitudResponseDTO,
         BuscarSolicitudDePagoSinPedidoRequestDTO,
         BuscarSolicitudDePagoSinPedidoResponseDTO,
         CambiarEstatusSolicitudAutorizadaRequestDTO,
         CambiarEstatusSolicitudAutorizadaResponseDTO,
         CambiarSolicitudEnAutorizacionRequestDTO,
         CambiarSolicitudEnAutorizacionResponseDTO,
         ListadoSolicitudesEstatusResponseDTO,
         RechazoEstatusSolicitudAutorizadaRequestDTO,
         RechazoEstatusSolicitudAutorizadaResponseDTO,
         VisorFacturaXMLRequestDTO,
         VisorFacturaXMLResponseDTO,
         EditDetalleSolicitudRequestDTO,
         EditDetalleSolicitudResponseDTO,
         DeleteDetalleSolicitudRequestDTO,
         DeleteDetalleSolicitudResponseDTO,
         PagarSolicitudRequestDTO,
         PagarSolicitudResponseDTO
        } from '../../dto/SolicitudDTO';

@Injectable({
  providedIn: 'root',
})
export class SolicitudRestRepository extends SolicitudRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listaSolicitud(): Observable<ListadoSolicitudesEstatusResponseDTO> {
    const endpoint = environment.lista_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListadoSolicitudesEstatusResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarSolicitud(request: AgregarSolicitudRequestDTO): Observable<AgregarSolicitudResponseDTO> {
    const endpoint = environment.agregar_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarPagoSolicitud(request: PagarSolicitudRequestDTO): Observable<PagarSolicitudResponseDTO> {
    const endpoint = environment.pagar_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<PagarSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarTipoPagoCheque(request: AgregarTipoPagoChequeRequestDTO): Observable<AgregarTipoPagoChequeResponseDTO> {
    const endpoint = environment.agregar_tipo_pago_cheque_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarTipoPagoChequeResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarTipoPagoTransferencia(request: AgregarTipoPagoTransferenciaRequestDTO): Observable<AgregarTipoPagoTransferenciaResponseDTO> {
    const endpoint = environment.agregar_tipo_pago_transferencia_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarTipoPagoTransferenciaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarReciboTipoPagoCheque(request: FormData): Observable<AgregarReciboTipoPagoChequeResponseDTO> {
    const endpoint = environment.agregar_recibo_tipo_pago_cheque_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarReciboTipoPagoChequeResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarPagoExtranjero(request: AgregarPagoExtranjeroRequestDTO): Observable<AgregarPagoExtranjeroResponseDTO> {
    const endpoint = environment.agregar_pago_extranjero_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarPagoExtranjeroResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarArchivoCargaProrrateo(
    request: AgregarArchivoCargaProrrateoRequestDTO): Observable<AgregarArchivoCargaProrrateoResponseDTO> {
    const endpoint = environment.agregar_archivo_carga_prorrateo_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarArchivoCargaProrrateoResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  aplicarDetalleSolicitud(
    request: AplicarDetalleSolicitudRequestDTO): Observable<AplicarDetalleSolicitudResponseDTO> {
    const endpoint = environment.aplicar_detalle_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AplicarDetalleSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  aplicarMontoTotalSolicitud(
    request: AplicarMontoTotalSolicitudRequestDTO): Observable<AplicarMontoTotalSolicitudResponseDTO> {
    const endpoint = environment.aplicar_monto_total_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AplicarMontoTotalSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarDetalleSolicitud(request: BuscarDetalleDeSolicitudRequestDTO): Observable<BuscarDetalleDeSolicitudResponseDTO> {
    const endpoint = environment.buscar_detalle_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<BuscarDetalleDeSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarSolicitudSinPedido(request: BuscarSolicitudDePagoSinPedidoRequestDTO): Observable<BuscarSolicitudDePagoSinPedidoResponseDTO> {
    const endpoint = environment.buscar_solicitud_pago_sin_pedido;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<BuscarSolicitudDePagoSinPedidoResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  cambiarEstatusSolicitudAutorizada(
    request: CambiarEstatusSolicitudAutorizadaRequestDTO): Observable<CambiarEstatusSolicitudAutorizadaResponseDTO> {
    const endpoint = environment.cambiar_estatus_solicitud_autorizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<CambiarEstatusSolicitudAutorizadaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  rechazoEstatusSolicitudAutorizada(
    request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO> {
    const endpoint = environment.rechazo_estatus_solicitud_autorizada_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RechazoEstatusSolicitudAutorizadaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  visorFacturaXML(
    request: VisorFacturaXMLRequestDTO): Observable<VisorFacturaXMLResponseDTO> {
    const endpoint = environment.visor_factura_XML_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<VisorFacturaXMLResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  cambiarEstatusSolicitudEnAutorizacion(
    request: CambiarSolicitudEnAutorizacionRequestDTO): Observable<CambiarSolicitudEnAutorizacionResponseDTO> {
    const endpoint = environment.cambiar_estatus_solicitud_enAutorizacion_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<CambiarSolicitudEnAutorizacionResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarAcreedorPorSociedad(request: BuscarAcreedorPorSociedadRequestDTO): Observable<BuscarAcreedorPorSociedadResponseDTO> {
    const endpoint = environment.buscar_Acreedor_Por_Sociedad_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<BuscarAcreedorPorSociedadResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editDetalleSolicitud(request: EditDetalleSolicitudRequestDTO): Observable<EditDetalleSolicitudResponseDTO> {
    const endpoint = environment.editar_detalle_endpoint;
    const {idDetalle, ...detalle} = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<EditDetalleSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}/${idDetalle}`,
        JSON.stringify(detalle),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteDetalleSolicitud(request: DeleteDetalleSolicitudRequestDTO): Observable<DeleteDetalleSolicitudResponseDTO> {
    const endpoint = environment.delete_detalle_endpoint;
    const {id, ...detalle} = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<DeleteDetalleSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(detalle),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
