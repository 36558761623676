import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ActivarDesactivarAutorizadorAreaRequestDTO,
         ActivarDesactivarAutorizadorAreaResponseDTO,
         AgregarAutorizadorAreaRequestDTO,
         AgregarAutorizadorAreaResponseDTO,
         BuscarAutorizadorAreaRequestDTO,
         EditarAutorizadorAreaRequestDTO,
         EditarAutorizadorAreaResponseDTO,
         ListaAutorizadoresAreaResponseDTO,
        } from '../../dto/AutorizadoresAreaDTO';
import { AutorizadoresAreaRepository
        } from 'src/app/core/repositories/AutorizadoresArea/AutorizadoresAreaRepository';

@Injectable({
    providedIn: 'root',
})

export class AutorizadoresAreaRestRepository extends AutorizadoresAreaRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listaAutorizadoresArea(): Observable<ListaAutorizadoresAreaResponseDTO> {
        const endpoint = environment.lista_autorizadores_area_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ListaAutorizadoresAreaResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    agregarAutorizadorArea(request: AgregarAutorizadorAreaRequestDTO): Observable<AgregarAutorizadorAreaResponseDTO> {
        const endpoint = environment.agregar_autorizador_area_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
          .post<AgregarAutorizadorAreaResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      buscarAutorizadorArea(request: BuscarAutorizadorAreaRequestDTO): Observable<ListaAutorizadoresAreaResponseDTO> {
        const endpoint = environment.buscar_autorizador_area_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
          .post<ListaAutorizadoresAreaResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      activarDesactivarAutorizadorArea(
        request: ActivarDesactivarAutorizadorAreaRequestDTO): Observable<ActivarDesactivarAutorizadorAreaResponseDTO> {
        const endpoint = environment.activar_desactivar_autorizador_area_endpoint;
        const {intIDAutorizadorArea, ...moneda} = request;
        const httpOptions = { headers: this.headers };
        return this.http
          .put<ActivarDesactivarAutorizadorAreaResponseDTO>(
            `${environment.url_base}/${endpoint}/${intIDAutorizadorArea}`,
            JSON.stringify(moneda),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      editarAutorizador(request: EditarAutorizadorAreaRequestDTO): Observable<EditarAutorizadorAreaResponseDTO> {
        const endpoint = environment.editar_autorizador_area_endpoint;
        const {intIDAutorizadorArea, ...autorizador} = request;
        const httpOptions = { headers: this.headers };
        return this.http
          .put<EditarAutorizadorAreaResponseDTO>(
            `${environment.url_base}/${endpoint}/${intIDAutorizadorArea}`,
            JSON.stringify(autorizador),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }
}
