import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { EstatusSolicitudesRepository } from 'src/app/core/repositories/EstatusSolicitudes/EstatusSolicitudesRepository';
import { ActivarDesactivarEstutusSolicitudRequestDTO,
         ActivarDesactivarEstutusSolicitudResponseDTO,
         AgregarEstatusSolicitudRequestDTO,
         AgregarEstatusSolicitudResponseDTO,
         BuscarEstatusSolicitudRequestDTO,
         BuscarEstatusSolicitudResponseDTO,
         EditarEstatusSolicitudRequestDTO,
         EditarEstatusSolicitudResponseDTO,
         ListaEstatusSolicitudesResponseDTO,
} from '../../dto/EstatusSolicitudesDTO';

@Injectable({
  providedIn: 'root',
})
export class EstatusSolicitudesRestRepository extends EstatusSolicitudesRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }



  listaEstatusSolicitudes(): Observable<ListaEstatusSolicitudesResponseDTO> {
    const endpoint = environment.lista_estatus_solicitudes_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListaEstatusSolicitudesResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  agregarEstatusSolicitud(request: AgregarEstatusSolicitudRequestDTO): Observable<AgregarEstatusSolicitudResponseDTO> {
    const endpoint = environment.agregar_estatus_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AgregarEstatusSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarEstatusSolicitud(request: BuscarEstatusSolicitudRequestDTO): Observable<BuscarEstatusSolicitudResponseDTO> {
    const endpoint = environment.buscar_estatus_solicitud_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<BuscarEstatusSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  activarDesactivarEstatusSolicitud(
    request: ActivarDesactivarEstutusSolicitudRequestDTO): Observable<ActivarDesactivarEstutusSolicitudResponseDTO> {
    const endpoint = environment.activar_desactivar_estatus_solicitud_endpoint;
    const { intIDEstatus, ...estatus } = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ActivarDesactivarEstutusSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDEstatus}`,
        JSON.stringify(estatus),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editarEstatusSolicitud(request: EditarEstatusSolicitudRequestDTO): Observable<EditarEstatusSolicitudResponseDTO> {
    const endpoint = environment.editar_estatus_solicitud_endpoint;
    const { intIDEstatus, ...motivo } = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<EditarEstatusSolicitudResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDEstatus}`,
        JSON.stringify(motivo),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
