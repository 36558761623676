import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth/interceptor';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LayoutComponent } from './shared/components/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'request-payment',
        loadChildren: () =>
          import('./formato-unic/solicitud-pago/solicitud-pago.module').then((m) => m.SolicitudPagoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'new-request',
        loadChildren: () =>
          import('./formato-unic/nueva-solicitud/nueva-solicitud.module').then((m) => m.NuevaSolicitudModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'authorize-request',
        loadChildren: () =>
          import('./formato-unic/cambio-estatus-solicitud/cambio-solicitud-autorizada/cambio-solicitud-autorizada.module')
          .then((m) => m.CambioSolicitudAutorizadaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'post-request',
        loadChildren: () =>
          import('./formato-unic/cambio-estatus-solicitud/cambio-solicitud-contabilizada/cambio-solicitud-contabilizada.module')
          .then((m) => m.CambioSolicitudContabilizadaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'schedule-payment-request',
        loadChildren: () =>
          import('./formato-unic/cambio-estatus-solicitud/cambio-solicitud-programar-pago/cambio-solicitud-programar-pago.module')
          .then((m) => m.CambioSolicitudProgramarPagoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'pay-request',
        loadChildren: () =>
          import('./formato-unic/cambio-estatus-solicitud/cambio-solicitud-pagar-solicitud/cambio-solicitud-pagar-solicitud.module')
          .then((m) => m.CambioSolicitudPagarSolicitudModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'menu-catalog',
        loadChildren: () =>
          import('./formato-unic/menu-catalogos/menu-catalogos.module').then((m) => m.MenuCatalogosModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-society',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-sociedad/catalogo-sociedad/catalogo-sociedad.module').then(
                (m) => m.CatalogoSociedadModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-society',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-sociedad/agregar-sociedad/agregar-sociedad.module').then(
                (m) => m.AgregarSociedadModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-coin',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-monedas/moneda/moneda.module').then(
                (m) => m.MonedaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-coin',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-monedas/insertar-moneda/insertar-moneda.module').then(
                (m) => m.InsertarMonedaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-taxes',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-impuestos/impuestos/impuestos.module').then((m) => m.ImpuestosModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-taxes',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-impuestos/agregar-impuesto/agregar-impuesto.module').then(
                (m) => m.AgregarImpuestoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-areas-departments',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-areas/areas-departamentos/areas-departamentos.module').then(
                (m) => m.AreasDepartamentosModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-area',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-areas/agregar-area/agregar-area.module').then((m) => m.AgregarAreaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-applicants-areas',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-solicitantes/catalogo-solicitantes-area/catalogo-solicitantes-area.module').then(
                (m) => m.CatalogoSolicitantesAreaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-applicants-areas',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-solicitantes/agregar-solicitante/agregar-solicitante.module').then(
                (m) => m.AgregarSolicitanteModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-authorizers-areas',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-autorizadores/catalogo-autorizadores/catalogo-autorizadores.module').then(
                (m) => m.CatalogoAutorizadoresModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-authorizers-areas',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-autorizadores/agregar-autorizador/agregar-autorizador.module').then(
                (m) => m.AgregarAutorizadorModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-rejection-reasons',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-motivos-rechazo/motivos-rechazo/motivos-rechazo.module').then(
                (m) => m.MotivosRechazoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-rejection-reasons',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-motivos-rechazo/agregar-motivos-rechazo/agregar-motivos-rechazo.module').then(
                (m) => m.AgregarMotivosRechazoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-status-request',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-estatus-solicitudes/estatus-solicitud/estatus-solicitud.module').then(
                (m) => m.EstatusSolicitudModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-status-request',
        loadChildren: () =>
          import('./formato-unic/catalogos/catalogo-estatus-solicitudes/agregar-estatus-solicitud/agregar-estatus-solicitud.module').then(
                (m) => m.AgregarEstatusSolicitudModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'deliver-check',
        loadChildren: () =>
          import('./formato-unic/entregar-cheque/entregar-cheque.module').then(
                (m) => m.EntregarChequeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog-users',
        loadChildren: () =>
          import('./formato-unic/catalogo-usuarios/lista-usuarios/catalogo-usuarios.module').then(
                (m) => m.CatalogoUsuariosModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-users',
        loadChildren: () =>
          import('./formato-unic/catalogo-usuarios/alta-usuarios/alta-usuarios.module').then(
                (m) => m.AltaUsuariosModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
      canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
