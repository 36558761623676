import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Localization } from '../../Localization';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    public readonly localization: Localization,
    private router: Router,
    private authenticationService: AuthService
  ) {}

  ngOnInit(): void {}

  closeLogout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    localStorage.removeItem('User');
    localStorage.clear();
  }

  onReturn(): void {
    this.router.navigate(['/']);
  }
}
