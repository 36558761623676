import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { MonedaRepository } from 'src/app/core/repositories/moneda/MonedaRepository';
import { ListaMonedaResponseDTO,
         InsertarMonedaRequestDTO,
         InsertarMonedaResponseDTO,
         EditarMonedaRequestDTO,
         EditarMonedaResponseDTO,
         ActivarDesactivarMonedaRequestDTO,
         ActivarDesactivarMonedaResponseDTO,
         BuscarMonedaRequestDTO,
         BuscarMonedaResponseDTO,
        } from '../../dto/MonedaDTO';

@Injectable({
  providedIn: 'root',
})
export class MonedaRestRepository extends MonedaRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }



  listaMonedas(): Observable<ListaMonedaResponseDTO> {
    const endpoint = environment.lista_monedas_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListaMonedaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  nuevaMoneda(request: InsertarMonedaRequestDTO): Observable<InsertarMonedaResponseDTO> {
    const endpoint = environment.insertar_moneda_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<InsertarMonedaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editarMoneda(request: EditarMonedaRequestDTO): Observable<EditarMonedaResponseDTO> {
    const endpoint = environment.editar_moneda_endpoint;
    const {intIDMoneda, ...moneda} = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<EditarMonedaResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDMoneda}`,
        JSON.stringify(moneda),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarMoneda(request: BuscarMonedaRequestDTO): Observable<BuscarMonedaResponseDTO> {
    const endpoint = environment.buscar_moneda_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<BuscarMonedaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  activarDesactivarMoneda(request: ActivarDesactivarMonedaRequestDTO): Observable<ActivarDesactivarMonedaResponseDTO> {
    const endpoint = environment.activar_desactivar_moneda_endpoint;
    const {intIDMoneda, ...moneda} = request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ActivarDesactivarMonedaResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDMoneda}`,
        JSON.stringify(moneda),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
