import { Observable } from 'rxjs';
import { ListaSolicitantesResponseDTO,
         BuscarSolicitanteRequestDTO,
         EditarSolicitanteRequestDTO,
         EditarSolicitanteResponseDTO,
         ActivarDesactivarSolicitanteRequestDTO,
         ActivarDesactivarSolicitanteResponseDTO,
         AgregarSolicitanteRequestDTO,
         AgregarSolicitanteResponseDTO
        } from 'src/app/data/dto/SolicitantesAreaDTO';

export abstract class SolicitantesAreaRepository {
    abstract listaSolicitantesArea(): Observable<ListaSolicitantesResponseDTO>;
    abstract editarSolicitanteArea(request: EditarSolicitanteRequestDTO): Observable<EditarSolicitanteResponseDTO>;
    abstract agregarSolicitanteArea(request: AgregarSolicitanteRequestDTO): Observable<AgregarSolicitanteResponseDTO>;
    abstract buscarSolicitanteArea(request: BuscarSolicitanteRequestDTO): Observable<ListaSolicitantesResponseDTO>;
    abstract activarDesactivarSolicitanteArea(
        request: ActivarDesactivarSolicitanteRequestDTO): Observable<ActivarDesactivarSolicitanteResponseDTO>;
}
