import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { Localization, SpanishLocalization } from './shared/Localization';
import { ErrorInterceptor, JwtInterceptor } from './auth/interceptor';
import { UserRestRepository } from './data/repository/auth/UserRestRepository';
import { UserRepository } from './core/repositories/auth/UserRepository';
import { SociedadRepository } from './core/repositories/sociedad/SociedadRepository';
import { SociedadRestRepository } from './data/repository/sociedad/SociedadRestRepository';
import { MonedaRepository } from './core/repositories/moneda/MonedaRepository';
import { MonedaRestRepository } from './data/repository/moneda/MonedaRestRepository';
import { AutorizadoresAreaRepository } from './core/repositories/AutorizadoresArea/AutorizadoresAreaRepository';
import { AutorizadoresAreaRestRepository } from './data/repository/AutorizadoresArea/AutorizadoresAreaRestRepository';
import { AreaDepartamentoRestRepository } from './data/repository/AreaDepartamento/AreaDepartamentoRestRepository';
import { AreaDepartamentoRepository } from './core/repositories/AreaDepartamento/AreaDepartamentoRepository';
import { ImpuestosRepository } from './core/repositories/Impuestos/ImpuestosRepository';
import { ImpuestosRestRepository } from './data/repository/Impuestos/ImpuestosRestRepository';
import { SolicitudRepository } from './core/repositories/Solicitud/SolicitudRepository';
import { SolicitudRestRepository } from './data/repository/Solicitud/SolicitudRestRepository';
import { SolicitantesAreaRepository } from './core/repositories/SolicitantesArea/SolicitantesAreaRepository';
import { SolicitantesAreaRestRepository } from './data/repository/SolicitantesArea/SolicitantesAreaRestRepository';
import { UsuariosRepository } from './core/repositories/Usuarios/UsuariosRepository';
import { UsuariosRestRepository } from './data/repository/Usuarios/UsuariosRestRepository';
import { MotivosRechazoRepository } from './core/repositories/MotivosRechazo/MotivosRechazoRepository';
import { MotivosRechazoRestRepository } from './data/repository/MotivosRechazo/MotivosRechazoRestRepository';
import { EstatusSolicitudesRepository } from './core/repositories/EstatusSolicitudes/EstatusSolicitudesRepository';
import { EstatusSolicitudesRestRepository } from './data/repository/EstatusSolicitudes/EstatusSolicitudesRestRepository';
import { CambioEstatusSolicitudesRepository } from './core/repositories/CambioEstatusSolicitudes/CambioEstatusSolicitudesRepository';
import { CambioEstatusSolicitudRestRepository } from './data/repository/CambioEstatusSolicitud/CambioEstatusSolicitudRestRepository';
import { CentroCostosRepository } from './core/repositories/CentroCostos/CentroCostosRepository';
import { CentroCostosRestRepository } from './data/repository/CentroCostos/CentroCostosRestRepository';
import { CuentasContablesRepository } from './core/repositories/CuentasContables/CuentasContablesRepository';
import { CuentasContablesRestRepository } from './data/repository/CuentasContables/CuentasContablesRestRepository';
import { EntregaChequesRepository } from './core/repositories/EntregaCheques/EntregaChequesRpository';
import { EntregarChequeRestRepository } from './data/repository/EntregarCheque/EntregarChequeRestRepository';
import { PerfilesRestRepository } from './data/repository/Perfil/PerfilesRestRepository';
import { PerfilesRepository } from './core/repositories/Perfiles/PerfilesRepository';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: Localization, useClass: SpanishLocalization },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: UserRepository, useClass: UserRestRepository },
    { provide: SociedadRepository, useClass: SociedadRestRepository},
    { provide: MonedaRepository, useClass: MonedaRestRepository},
    { provide: AutorizadoresAreaRepository, useClass: AutorizadoresAreaRestRepository},
    { provide: AreaDepartamentoRepository, useClass: AreaDepartamentoRestRepository},
    { provide: ImpuestosRepository, useClass: ImpuestosRestRepository},
    { provide: SolicitudRepository, useClass: SolicitudRestRepository},
    { provide: SolicitantesAreaRepository, useClass: SolicitantesAreaRestRepository},
    { provide: UsuariosRepository, useClass: UsuariosRestRepository},
    { provide: MotivosRechazoRepository, useClass: MotivosRechazoRestRepository},
    { provide: EstatusSolicitudesRepository, useClass: EstatusSolicitudesRestRepository},
    { provide: CambioEstatusSolicitudesRepository, useClass: CambioEstatusSolicitudRestRepository},
    { provide: CentroCostosRepository, useClass: CentroCostosRestRepository},
    { provide: CuentasContablesRepository, useClass: CuentasContablesRestRepository},
    { provide: EntregaChequesRepository, useClass: EntregarChequeRestRepository },
    { provide: PerfilesRepository, useClass: PerfilesRestRepository},
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
