import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { SociedadRepository } from 'src/app/core/repositories/sociedad/SociedadRepository';
import {
  ListaSociedadResponseDTO,
  NuevaSociedadRequestDTO,
  NuevaSociedadResponseDTO,
  EditarSociedadRequestDTO,
  EditarSociedadResponseDTO,
  BuscarSociedadRequestDTO,
  BuscarSociedadResponseDTO,
  ActivarDesactivarSociedadRequestDTO,
  ActivarDesactivarSociedadResponseDTO,
} from '../../dto/SociedadDTO';

@Injectable({
  providedIn: 'root',
})
export class SociedadRestRepository extends SociedadRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }



  listaSociedades(): Observable<ListaSociedadResponseDTO> {
    const endpoint = environment.lista_sociedades_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListaSociedadResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  nuevaSociedad(request: NuevaSociedadRequestDTO): Observable<NuevaSociedadResponseDTO> {
    const endpoint = environment.nueva_sociedad_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<NuevaSociedadResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editarSociedad(request: EditarSociedadRequestDTO): Observable<EditarSociedadResponseDTO> {
    const endpoint = environment.editar_sociedad_endpoint;
    const {intIDSociedad, ...sociedad} = request;
    console.log(sociedad);
    const httpOptions = { headers: this.headers };
    return this.http
      .put<EditarSociedadResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDSociedad}`,
        JSON.stringify(sociedad),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  buscarSociedad(request: BuscarSociedadRequestDTO): Observable<BuscarSociedadResponseDTO> {
    const endpoint = environment.buscar_sociedad_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<BuscarSociedadResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  activarDesactivarSociedad(request: ActivarDesactivarSociedadRequestDTO): Observable<ActivarDesactivarSociedadResponseDTO> {
    const endpoint = environment.activar_desactivar_sociedad_endpoint;
    const {intIDSociedad, ...sociedad} = request;
    console.log(sociedad);
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ActivarDesactivarSociedadResponseDTO>(
        `${environment.url_base}/${endpoint}/${intIDSociedad}`,
        JSON.stringify(sociedad),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
