import { Observable } from 'rxjs';
import { ListadoSolicitudesEstatusResponseDTO,
         AgregarSolicitudRequestDTO,
         AgregarSolicitudResponseDTO,
         AgregarTipoPagoChequeRequestDTO,
         AgregarTipoPagoChequeResponseDTO,
         AgregarTipoPagoTransferenciaRequestDTO,
         AgregarTipoPagoTransferenciaResponseDTO,
         AgregarReciboTipoPagoChequeResponseDTO,
         AgregarPagoExtranjeroRequestDTO,
         AgregarPagoExtranjeroResponseDTO,
         AgregarArchivoCargaProrrateoRequestDTO,
         AgregarArchivoCargaProrrateoResponseDTO,
         AplicarDetalleSolicitudRequestDTO,
         AplicarDetalleSolicitudResponseDTO,
         AplicarMontoTotalSolicitudRequestDTO,
         AplicarMontoTotalSolicitudResponseDTO,
         BuscarDetalleDeSolicitudRequestDTO,
         BuscarDetalleDeSolicitudResponseDTO,
         BuscarSolicitudDePagoSinPedidoRequestDTO,
         BuscarSolicitudDePagoSinPedidoResponseDTO,
         CambiarEstatusSolicitudAutorizadaRequestDTO,
         CambiarEstatusSolicitudAutorizadaResponseDTO,
         RechazoEstatusSolicitudAutorizadaRequestDTO,
         RechazoEstatusSolicitudAutorizadaResponseDTO,
         CambiarSolicitudEnAutorizacionRequestDTO,
         CambiarSolicitudEnAutorizacionResponseDTO,
         VisorFacturaXMLRequestDTO,
         VisorFacturaXMLResponseDTO,
         BuscarAcreedorPorSociedadRequestDTO,
         BuscarAcreedorPorSociedadResponseDTO,
         EditDetalleSolicitudRequestDTO,
         EditDetalleSolicitudResponseDTO,
         DeleteDetalleSolicitudRequestDTO,
         DeleteDetalleSolicitudResponseDTO,
         PagarSolicitudRequestDTO,
         PagarSolicitudResponseDTO
        } from 'src/app/data/dto/SolicitudDTO';

export abstract class SolicitudRepository {
    abstract listaSolicitud(): Observable<ListadoSolicitudesEstatusResponseDTO>;
    abstract buscarAcreedorPorSociedad(request: BuscarAcreedorPorSociedadRequestDTO): Observable<BuscarAcreedorPorSociedadResponseDTO>;
    abstract agregarSolicitud(request: AgregarSolicitudRequestDTO): Observable<AgregarSolicitudResponseDTO>;
    abstract agregarTipoPagoCheque(request: AgregarTipoPagoChequeRequestDTO): Observable<AgregarTipoPagoChequeResponseDTO>;
    abstract agregarTipoPagoTransferencia(
        request: AgregarTipoPagoTransferenciaRequestDTO): Observable<AgregarTipoPagoTransferenciaResponseDTO>;
    abstract agregarReciboTipoPagoCheque(
        request: FormData): Observable<AgregarReciboTipoPagoChequeResponseDTO>;
    abstract agregarPagoExtranjero(
        request: AgregarPagoExtranjeroRequestDTO): Observable<AgregarPagoExtranjeroResponseDTO>;
    abstract agregarArchivoCargaProrrateo(
            request: AgregarArchivoCargaProrrateoRequestDTO): Observable<AgregarArchivoCargaProrrateoResponseDTO>;
    abstract aplicarDetalleSolicitud(
            request: AplicarDetalleSolicitudRequestDTO): Observable<AplicarDetalleSolicitudResponseDTO>;
    abstract aplicarMontoTotalSolicitud(
            request: AplicarMontoTotalSolicitudRequestDTO): Observable<AplicarMontoTotalSolicitudResponseDTO>;
    abstract buscarDetalleSolicitud(
            request: BuscarDetalleDeSolicitudRequestDTO): Observable<BuscarDetalleDeSolicitudResponseDTO>;
    abstract buscarSolicitudSinPedido(
            request: BuscarSolicitudDePagoSinPedidoRequestDTO): Observable<BuscarSolicitudDePagoSinPedidoResponseDTO>;
    abstract cambiarEstatusSolicitudAutorizada(
            request: CambiarEstatusSolicitudAutorizadaRequestDTO): Observable<CambiarEstatusSolicitudAutorizadaResponseDTO>;
    abstract rechazoEstatusSolicitudAutorizada(
            request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO>;
    abstract visorFacturaXML(
            request: VisorFacturaXMLRequestDTO): Observable<VisorFacturaXMLResponseDTO>;
    abstract cambiarEstatusSolicitudEnAutorizacion(
            request: CambiarSolicitudEnAutorizacionRequestDTO): Observable<CambiarSolicitudEnAutorizacionResponseDTO>;
    abstract editDetalleSolicitud(request: EditDetalleSolicitudRequestDTO): Observable<EditDetalleSolicitudResponseDTO>;
    abstract deleteDetalleSolicitud(request: DeleteDetalleSolicitudRequestDTO): Observable<DeleteDetalleSolicitudResponseDTO>;
    abstract agregarPagoSolicitud(request: PagarSolicitudRequestDTO): Observable<PagarSolicitudResponseDTO>;
}

