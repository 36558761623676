import { Observable } from 'rxjs';
import {ListaMonedaResponseDTO,
        InsertarMonedaRequestDTO,
        InsertarMonedaResponseDTO,
        EditarMonedaRequestDTO,
        EditarMonedaResponseDTO,
        BuscarMonedaRequestDTO,
        BuscarMonedaResponseDTO,
        ActivarDesactivarMonedaRequestDTO,
        ActivarDesactivarMonedaResponseDTO
        } from 'src/app/data/dto/MonedaDTO';

export abstract class MonedaRepository {
    abstract listaMonedas(): Observable<ListaMonedaResponseDTO>;
    abstract nuevaMoneda(request: InsertarMonedaRequestDTO): Observable<InsertarMonedaResponseDTO>;
    abstract editarMoneda(request: EditarMonedaRequestDTO): Observable<EditarMonedaResponseDTO>;
    abstract buscarMoneda(request: BuscarMonedaRequestDTO): Observable<BuscarMonedaResponseDTO>;
    abstract activarDesactivarMoneda(request: ActivarDesactivarMonedaRequestDTO): Observable<ActivarDesactivarMonedaResponseDTO>;
}

