import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ActivarDesactivarSolicitanteRequestDTO,
         ActivarDesactivarSolicitanteResponseDTO,
         AgregarSolicitanteRequestDTO,
         AgregarSolicitanteResponseDTO,
         BuscarSolicitanteRequestDTO,
         EditarSolicitanteRequestDTO,
         EditarSolicitanteResponseDTO,
         ListaSolicitantesResponseDTO,
        } from '../../dto/SolicitantesAreaDTO';
import { SolicitantesAreaRepository
        } from 'src/app/core/repositories/SolicitantesArea/SolicitantesAreaRepository';

@Injectable({
    providedIn: 'root',
})

export class SolicitantesAreaRestRepository extends SolicitantesAreaRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listaSolicitantesArea(): Observable<ListaSolicitantesResponseDTO> {
        const endpoint = environment.lista_solicitantes_area_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ListaSolicitantesResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    agregarSolicitanteArea(request: AgregarSolicitanteRequestDTO): Observable<AgregarSolicitanteResponseDTO> {
        const endpoint = environment.agregar_solicitante_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
          .post<AgregarSolicitanteResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      buscarSolicitanteArea(request: BuscarSolicitanteRequestDTO): Observable<ListaSolicitantesResponseDTO> {
        const endpoint = environment.buscar_solicitante_area_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
          .post<ListaSolicitantesResponseDTO>(
            `${environment.url_base}/${endpoint}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      activarDesactivarSolicitanteArea(
        request: ActivarDesactivarSolicitanteRequestDTO): Observable<ActivarDesactivarSolicitanteResponseDTO> {
        const endpoint = environment.activar_desactivar_solicitante_area_endpoint;
        const {intIDSolicitantePoArea, ...moneda} = request;
        const httpOptions = { headers: this.headers };
        return this.http
          .put<ActivarDesactivarSolicitanteResponseDTO>(
            `${environment.url_base}/${endpoint}/${intIDSolicitantePoArea}`,
            JSON.stringify(moneda),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

      editarSolicitanteArea(request: EditarSolicitanteRequestDTO): Observable<EditarSolicitanteResponseDTO> {
        const endpoint = environment.editar_solicitante_area_endpoint;
        const {intIDSolicitantePoArea, ...solicitante} = request;
        const httpOptions = { headers: this.headers };
        return this.http
          .put<EditarSolicitanteResponseDTO>(
            `${environment.url_base}/${endpoint}/${intIDSolicitantePoArea}`,
            JSON.stringify(solicitante),
            httpOptions
          )
          .pipe(
            map((result) => {
              return result;
            })
          );
      }
}
