import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CentroCostosRepository } from 'src/app/core/repositories/CentroCostos/CentroCostosRepository';
import { ListadoCentroCostosResponseDTO } from '../../dto/CentroCostosDTO';

@Injectable({
  providedIn: 'root',
})
export class CentroCostosRestRepository extends CentroCostosRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listaCentroCostos(): Observable<ListadoCentroCostosResponseDTO> {
    const endpoint = environment.lista_centro_costos_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
    .get<ListadoCentroCostosResponseDTO>(
      `${environment.url_base}/${endpoint}`,
      httpOptions
    )
    .pipe(
      map((result) => {
        return result;
      })
    );
  }
}
