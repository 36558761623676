import { Observable } from 'rxjs';
import { ActivarDesactivarAreaDepartamentoRequestDTO, ActivarDesactivarAreaDepartamentoResponseDTO } from 'src/app/data/dto/AreaDepartamentoDTO';
import { ListaAutorizadoresAreaResponseDTO,
         AgregarAutorizadorAreaRequestDTO,
         AgregarAutorizadorAreaResponseDTO,
         BuscarAutorizadorAreaRequestDTO,
         EditarAutorizadorAreaRequestDTO,
         EditarAutorizadorAreaResponseDTO
        } from 'src/app/data/dto/AutorizadoresAreaDTO';

export abstract class AutorizadoresAreaRepository {
    abstract listaAutorizadoresArea(): Observable<ListaAutorizadoresAreaResponseDTO>;
    abstract editarAutorizador(request: EditarAutorizadorAreaRequestDTO): Observable<EditarAutorizadorAreaResponseDTO>;
    abstract agregarAutorizadorArea(request: AgregarAutorizadorAreaRequestDTO): Observable<AgregarAutorizadorAreaResponseDTO>;
    abstract buscarAutorizadorArea(request: BuscarAutorizadorAreaRequestDTO): Observable<ListaAutorizadoresAreaResponseDTO>;
    abstract activarDesactivarAutorizadorArea(
        request: ActivarDesactivarAreaDepartamentoRequestDTO): Observable<ActivarDesactivarAreaDepartamentoResponseDTO>;
}
